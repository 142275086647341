import React, { useState } from 'react';
import { Mail, Linkedin, Send } from 'lucide-react';
import type { LucideIcon } from 'lucide-react';
import essential from '../../assets/rb_2149280669.png';

type SocialLinkProps = {
  icon: LucideIcon;
  href: string;
  label: string;
};

const SocialLink: React.FC<SocialLinkProps> = ({ icon: Icon, href, label }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center gap-2 text-[#004B49] hover:text-[#006664] transition-colors"
  >
    <Icon className="w-5 h-5" />
    <span>{label}</span>
  </a>
);

const CatPeek = ({ isVisible, onDismiss }: { isVisible: boolean; onDismiss: () => void }) => {
  if (!isVisible) return null;

  return (
    <div
      onClick={onDismiss}
      className="fixed bottom-0 right-0 flex items-end transition-transform duration-700 cursor-pointer animate-peek z-50"
    >
      <div className="relative">
        <div className="absolute bottom-full right-8 mb-4 bg-white p-3 rounded-lg shadow-lg origin-bottom-right animate-pop-in">
          <div className="relative">
            <p className="text-sm whitespace-nowrap font-medium text-gray-800">
              Just email them already...meow! 😺
            </p>
            <div className="absolute bottom-0 right-8 translate-y-full border-8 border-transparent border-t-white" />
          </div>
        </div>
        <img
          src={essential}
          alt="Curious cat peeking"
          className="w-48 h-48 md:w-64 md:h-64 object-contain"
        />
      </div>
    </div>
  );
};

export const ContactSection: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'success' | 'error' | null>(null);
  const [showCat, setShowCat] = useState(false);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const formData = new FormData(e.currentTarget);

      const formEntries = {
        'entry.1990824543': formData.get('email')?.toString() ?? '',
        'entry.1112458669': formData.get('message')?.toString() ?? ''
      };

      const FORM_ID = '1FAIpQLSdv-AfWy-IxbjysOb49Q80mPNqtENAwwSDkks6LT5KWRyPLKQ';

      await fetch(`https://docs.google.com/forms/d/e/${FORM_ID}/formResponse`, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(formEntries)
      });

      setSubmitStatus('success');
      (e.target as HTMLFormElement).reset();
    } catch (error) {
      console.error('Submission error:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
      setTimeout(() => setSubmitStatus(null), 5000);
    }
  };

  const toggleCat = () => {
    setShowCat(!showCat);
  };

  return (
    <div id="contact" className="min-h-screen py-[10vh] flex items-center relative">
      <div className="absolute inset-0 overflow-hidden">
        <img src="/api/placeholder/1920/1080" alt="background" className="w-full h-full object-cover" />
        <div className="absolute inset-0 bg-white/90" />
      </div>
      <div className="relative w-full px-4">
        <div className="bg-white/80 backdrop-blur-sm rounded-lg shadow-lg p-8 w-full mx-auto max-w-4xl">
          <h2 className="text-3xl font-bold mb-6 tracking-tight">You can reach me through:</h2>
          <div className="flex flex-col space-y-8">
            <div className="bg-white rounded-lg shadow-md p-6 shadow-[#004B49]/10">
              <div className="space-y-4">
                <SocialLink icon={Mail} href="mailto:grittydv@gmail.com" label="Email: grittydv@gmail.com" />
                <SocialLink icon={Linkedin} href="https://linkedin.com/in/sandormoricz" label="LinkedIn" />
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6 shadow-[#004B49]/10">
              <h3 className="text-xl font-semibold mb-4 text-[#004B49]">Send me a message</h3>
              <form onSubmit={onSubmit} className="space-y-6">
                <input
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#004B49]/20 focus:border-[#004B49] transition-all bg-white"
                  required
                  disabled={isSubmitting}
                />
                <textarea
                  name="message"
                  placeholder="Type your message here..."
                  className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#004B49]/20 focus:border-[#004B49] transition-all h-32 bg-white"
                  required
                  disabled={isSubmitting}
                ></textarea>
                <div className="flex items-center gap-4 flex-wrap">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-[#004B49] text-white px-8 py-3 rounded-lg hover:bg-[#006664] transition-colors flex items-center gap-2 disabled:opacity-50"
                  >
                    {isSubmitting ? 'Sending...' : 'Connect'}
                    <Send size={16} strokeWidth={1.5} />
                  </button>
                  <button
                    type="button"
                    onClick={toggleCat}
                    className={`px-4 py-3 rounded-lg transition-colors ${
                      showCat 
                        ? 'bg-emerald-200 text-emerald-800 hover:bg-emerald-300'
                        : 'bg-emerald-100 text-emerald-700 hover:bg-emerald-200'
                    }`}
                  >
                    {showCat ? 'Quick hide the cat!' : 'Or click here!'}
                  </button>
                </div>
                {submitStatus === 'success' && (
                  <div className="p-4 bg-green-50 text-green-700 rounded-lg">
                    Message sent successfully!
                  </div>
                )}
                {submitStatus === 'error' && (
                  <div className="p-4 bg-red-50 text-red-700 rounded-lg">
                    There was an error sending your message. Please try again.
                  </div>
                )}
              </form>
            </div>
          </div>

          <CatPeek
            isVisible={showCat}
            onDismiss={() => setShowCat(false)}
          />
        </div>
      </div>
    </div>
  );
};